<template>
  <v-container fluid>
    <v-row>

    </v-row>
        <v-row>

      <v-col cols="3">
        <v-card>
          <v-card-title> Conférence    <v-spacer></v-spacer>
           </v-card-title>
          <v-card-text>
            ...
          </v-card-text>

        </v-card>
      </v-col>
            <v-col cols="3">
        <v-card>
          <v-card-title> Séance    <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            ...
          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
    <v-row justify="center">

    </v-row>
  </v-container>
</template>
<script>
import { icp_request } from "../../config/axios";

export default {
  data: () => ({
    apiKey: "Bb5zUp9kQPajKTj6mBEJbA",
    apiSecret:"NdIuvNm75eW8T4AG03uFh9Weozs9OeVkchKM",
    leaveUrl: "http://frontendicp.s3-website.eu-west-3.amazonaws.com",
    meetingNumber: 95174908344,
    passWord: "1234",
    role: 1,
    userEmail: "akeitaisidk@gmail.com",
    userName: "bramslo17",
  }),
};
</script>
